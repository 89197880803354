.chart {
  background-color: white;
  margin: 10px;
  border-radius: 12px;
  box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
  height: 340px;
  padding-bottom: 25px;
  padding-right: 10px;

  .title {
    color: #111;
    font-family: "lexend";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110.5%; /* 19.89px */
    letter-spacing: -0.18px;
    padding: 12px 18px;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: gray;
  }
  .recharts-cartesian-axis-ticks {
    padding-top: 20px !important;
  }
}

.xAxis {
  margin-top: 20px !important;
  color: red !important;
  background-color: yellow !important;
}

@media screen and (max-width: 575px) {
  .chart {
    .title {
      display: none;
    }
  }
}
// .custom-tooltip {
//   // border: 1px solid #d5d5d5;
//   padding: 13px !important;
//   outline: none !important;
//   border: none !important;
//   box-shadow: none !important;

// }
// .custom-tooltip:hover {
//   border: none !important;
//   outline: none !important;
//   box-shadow: none !important;

// }
.heading_area{
  color: #111;
font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
font-family: 'lexend';
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.12px;
}
.total_count_area{
  color: #4E4E4E;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'lexend';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}
.barChartStats{
  color: rgba(0, 0, 0, 0.40);
text-align: center;
font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
font-family: 'lexend';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
letter-spacing: -0.12px;
}