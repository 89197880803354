.Widget_container {
  display: flex;
  padding: 10px;
  border-radius: 12px;
  height: 110px;
  background-color: #F6F8FA;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 10px;
  width: 14rem;
  .title {
    font-weight: bold;
  width: 97%;

    .icon_widget_transaction{
      border-radius: 8px;
background: #E3E8FA;
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
    }
  }
  .content {
    color: #111;
    font-family: 'lexend';
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 110.5%; /* 28.73px */
    letter-spacing: -0.26px;
    width: 95%;
    margin-top: 10%;
  }
  .item {
    font-size: 12px;
    font-weight: lighter;
    margin-top: 0;
    padding-top: 0;
    color: gray;
  }
}
@media screen and (max-width: 575px) {
  .widget {
    margin-bottom: 20px;
  }
  .Widget_container{
    width: 100%;
    height: 8rem;
  }
}
