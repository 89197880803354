.ocpp-link {
  font-style: italic;
  font-weight: bolder;
}
.copy-icon {
  display: flex;
  cursor: pointer;
  align-self: center;
  color: grey;
  &:hover {
    color: black;
  }
}
