.energy-container {
  background-color: rgb(235, 240, 240);
  .widgets {
    display: flex;
    padding: 10px 10px 0 10px;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 1vh;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 12px;
  }
  .chart-container {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto; /* Center the container */
  }
  
  @media screen and (max-width: 575px) {
    .widgets {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 1vh;
    flex-wrap: wrap;
    row-gap: 20px;
    .filterSec {
      width: min-content;
    }
  }
  .charts-sec {
    margin-top: 12px;
    display: grid;
    padding: 10px 10px 10px 10px;
    grid-template-rows: 50vh auto;

    gap: 20px;

    .chart-content {
      background-color: white;
      padding-bottom: 40px;
      border-radius: 12px;
      box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);

      .title {
        font-size: 16;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 8px;
      }
    }
    .below-chart {
      display: flex;
      flex-direction: row;

      gap: 20px;
      .chart-bar {
        border-radius: 12px;
        box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        width: 40%;
        background-color: white;

        .title {
          font-size: 16;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          padding: 8px;
        }
        .apex-chart {
          width: max-content;
        }
      }
      .chart-line {
        border-radius: 12px;
        box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
        width: 60%;
        background-color: white;

        .title {
          font-size: 16;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          padding: 8px;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .below-chart {
        display: flex;
        flex-direction: column;

        gap: 20px;
        .chart-bar {
          border-radius: 12px;
          box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          width: 100%;
          background-color: white;

          .title {
            font-size: 16;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            padding: 8px;
          }
          .apex-chart {
            width: max-content;
          }
        }
        .chart-line {
          border-radius: 12px;
          box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
          width: 100%;
          background-color: white;

          .title {
            font-size: 16;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            padding: 8px;
          }
        }
      }
    }
  }

  .chart2-content {
    height: fit-content;
    background-color: white;

    margin-top: 12px;
    border-radius: 12px;
    box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    .title {
      padding: 8px;
      font-size: 16;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }
}

.apexcharts-series.apexcharts-heatmap-series rect {
  stroke: white !important;
}

.apexcharts-toolbar{
 right: -22px !important;
 @media (min-width: 1030px) {
  right: -153px !important;
 }
}
// .apexcharts-legend-marker{
//   width: 40px !important;
// }
// .apexcharts-legend{
//   display: flex !important; 
//   flex-direction: column !important;
// }
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
 justify-content: start !important;
 
  @media (min-width: 1030px) {
   display: none;
  }
}

.legend_heat{
  @media (max-width: 1030px) {
   width: 102%;
   }
}

.legend_text{
  @media (max-width: 1030px) {
    font-size: 12px;
   }
}