// Widget container styling
.revenue-widget-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 17px 10px;
  border-radius: 15px;
  height: 8rem; 
  background: #F6F8FA;
  width: 14.7rem;
  .title {
    font-weight: bold;
  }
// Content
  .content {
    font-size: 18px;
    font-weight: bold;
    color: rgb(61, 57, 57);
  }
  .item {
    font-size: 12px;
    font-weight: lighter;
    margin-top: 0;
    padding-top: 0;
    color: gray;
  }
  .icon_widget_transaction{
    border-radius: 8px;
background: #E3E8FA;
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
  }
}
@media screen and (max-width: 575px) {
  .widget {
    margin-bottom: 20px;
  }
}
