.revenue-container {
  // background-color: rgb(235, 240, 240);
  .widgets {
    display: flex;
    padding: 10px 10px 0 10px;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 1vh;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 575px) {
    .widgets {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 1vh;
    flex-wrap: wrap;
    row-gap: 20px;
    .filterSec {
      width: min-content;
    }
  }
  .charts-sec {
    margin-top: 12px;
    display: grid;
    padding: 10px 10px 10px 10px;
    grid-template-rows: 50vh auto;

    gap: 20px;

    .chart-content {
      background-color: white;

      padding-bottom: 40px;
      border-radius: 12px;
      box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);

      .title {
        font-size: 16;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 8px;
      }
    }
  }

  .chart2-content {
    height: fit-content;
    background-color: white;

    margin-top: 12px;
    border-radius: 12px;
    box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
    .title {
      padding: 8px;
      font-size: 16;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }
}

.tab_heading{
  color: #111;
font-family: 'lexend';
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 110.5%; /* 24.31px */
letter-spacing: -0.22px;
margin: 1px 0px 15px 17px;
@media (max-width: 875px) {
font-size: 28px;    
margin: 10px 0px;
}
@media (max-width: 375px) {
font-size: 38px;    
}
}

