.cs-databaseTable {
  padding: 0px 10px;
  margin: 0px 10px;
  .label {
    display: flex;
    justify-content: center;
    border-radius: 39px;
    width: 80px;
    background: white;
    border: 3px solid green;
    .labelText {
      color: green;
      padding: 3px;
    }
  }

  .labelCompleted {
    display: flex;
    justify-content: center;
    border-radius: 39px;
    width: 80px;
    background: white;
    border: 3px solid rgb(183, 132, 39);
    .labelText {
      color: rgb(183, 132, 39);
      padding: 3px;
    }
  }
  .cellWithTable {
    display: felx;
    align-items: center;
    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
      color: rgba(42, 60, 136, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .cellWithStatus {
    padding: 5px;
    width: 10vh;
    height: 3vh;
    border-radius: 60%;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    align-items: center;

    &.Approved {
      background-color: black;
      color: green;
    }
    &.Pending {
      background-color: black;
      color: goldenrod;
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1pc rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1pc rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}
.dataGridContainer {
  max-height: 600px; /* Adjust this value to your preferred maximum height */
  overflow-y: auto;
}

@media screen and (max-width: 575px) {
  .cs-databaseTable {
    padding: 8px;
    margin: 0px;
  }
}
.custom-cell {
  font-family: "lexend" !important; /* Replace with your desired font-family */
  color: #111;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.5%; /* 17.68px */
}
.custom_cell_style{
  font-family: "lexend" !important; /* Replace with your desired font-family */
  color: #111;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110.5%;
}