.sideber-short::-webkit-scrollbar {
  width: 0.3em;
}
.sideber-short::-webkit-scrollbar-track {
  background-color: #fff;
}
.sideber-short::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar {
  width: 0.1em;
}
.sidebar::-webkit-scrollbar-track {
  background-color: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}

.sidebar {
  flex: 1;
  border: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: #fff;
  width: 200px;
  background-size: cover;
  box-shadow: 2px 4px 4px rgb(226, 226, 226);
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
  top: 0.5px;
  height: 100vh;
  .top {
    z-index: 0;
    margin-bottom: 10px;
    height: 56px;
   
    display: flex;

    .active_icon{
      color: #1ac47d;
    }

    .logo {
      cursor: pointer;
      height: 54px;
      width: 239px;
      padding: 0px;
      margin-top: 7px;
    }
    .upperIcon {
      cursor: pointer;
      color: rgb(0, 0, 0);
      height: 25px;
      &:hover {
        display: block;
        color: #1AC47D;
      }
    }
  }
  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }
  .logout {
    margin-top: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    color: white;
  }
  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        color: #111;
        font-family: 'lexend';
        cursor: pointer;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 110.5%; /* 19.89px */
        letter-spacing: -0.18px;
      }
      .list {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        color: white;
        margin: 10px 0px;
        .sideOption {
          color: rgb(0, 0, 0);
          display: flex;
          align-items: center;
        }
        &:hover {
          background: rgba(26, 196, 125, 0.10);
          color: white;
          margin: 10px 12px 10px 0px;
        }
        .icon {
         width: 20px;
         height: 20px;
          color: rgb(104, 98, 98);
          border-radius: 4px 8px 8px 4px;
        }
        span {
          color: #111;
          font-family: 'lexend';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110.5%; /* 17.68px */
          letter-spacing: -0.16px;
        }
      }
      .list-selected {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
       
        margin: 10px 12px 10px 2px;
        border-radius: 4px 8px 8px 4px;
        border-left: 3px solid #1AC47D ;
background: rgba(26, 196, 125, 0.10);

        .sideOption {
          display: inline-flex;
          align-items: center;
         
        }

        .icon {
          width: 20px;
          height: 20px;
          color: #1AC47D !important;
        
          margin-left: 7px;
        }
        span {
          color: #1AC47D;
font-family: 'lexend';
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.16px;
        }
      }
    }
    .copl {
      position: absolute;
      bottom: 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .title {
          font-size: 15px;
          font-weight: bold;
          margin-top: 8px;
          margin-bottom: 8px;
          color: white;
          cursor: pointer;
        }
        .list {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          color: white;

          .sideOption {
            color: white;

            display: inline-flex;
          }
          &:hover {
            background-color: #1AC47D;
            color: white;
          }
          .icon {
            font-size: 18px;
            color: white;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: rgb(255, 252, 252);
            margin-left: 10px;
            display: flex;
            align-items: center;
          }
        }
        .list-selected {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          color: white;
          background-color: #035634;
          color: white;
          .sideOption {
            color: white;

            display: inline-flex;
          }

          .icon {
            font-size: 18px;
            color: white;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: rgb(255, 252, 252);
            margin-left: 10px;
            align-items: center;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;
    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;
      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

.sideber-short {
  border: 0.5px solid rgb(230, 227, 227);
 
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 71px;
  background-size: cover;
  box-shadow: 2px 4px 4px rgb(226, 226, 226);
  position: sticky;
  top: 1px;
  height: 100vh;
  justify-content: center;
  // transition: 1s ease-out;

  .top {
    z-index: 0;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }
  .logout {
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 20px;
    cursor: pointer;
    color: white;
    .sideOptionHover {
      background-color: #035634;
      color: white;
    }
    .sideOption {
      display: none;
    }
  }
  .center {
    align-items: center;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 15px;
        font-weight: bold;
        display: none;
        color: white;
        cursor: pointer;
      }
      .list {
        display: flex;
        align-items: center;
        margin: 4px 7px;
        justify-content: center;
        cursor: pointer;
        color: white;
        .sideOptionHover {
          background-color: #035634;
          color: white;
        }
        .sideOption {
          color: white;
        }

        &:hover {
          background-color: #e1f5ed;
          color: black;
          padding: 0;
          margin: 4px 7px;
          
        }
        .icon {
          font-size: 30px;
          color: white;
          height: 30px;
          margin: 10px;
          max-height: 25px;
          width: 30px;
        }

        .menuIcon {
          font-size: 32px;
          color: #f5f5f5;
          margin-top: 10px;
          padding-right: 2px;
        }
        
        span {
          font-size: 13px;
          font-weight: 600;
          color: #888;

          display: none;
        }
      }
      .list-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 7px;
        padding: 0px 12px 12px;
        cursor: pointer;
        color: rgb(0, 0, 0);
        background: rgba(26, 196, 125, 0.10);
        .sideOptionHover {
          background-color: #035634;
          color: rgb(0, 0, 0);
        }
        .sideOption {
          color: white;
          
        }

        &:hover {
          color: white;
        }
        .icon {
          font-size: 25px;
          color: white;
          margin-top: 12px;
          max-height: 25px;
          width: 24px;
          height: 24px;
        }

        .menuIcon {
          font-size: 32px;
          color: #f5f5f5;
          margin-top: 10px;
          padding-right: 2px;
        }
        span {
          font-size: 13px;
          font-weight: 600;
          color: #888;

          display: none;
        }
      }
    }
    .copl {
      position: absolute;
      bottom: 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .title {
          font-size: 15px;
          font-weight: bold;
          display: none;
          color: white;
          cursor: pointer;
        }
        .list {
          display: flex;
          align-items: center;
          padding: 4px;

          cursor: pointer;
          color: white;
          .sideOptionHover {
            background-color: #035634;
            color: white;
          }
          .sideOption {
            color: white;
          }

          &:hover {
            background-color: #1AC47D;
            color: white;
          }
          .icon {
            font-size: 25px;
            color: white;
            margin-top: 12px;
            max-height: 25px;
          }
          

          .menuIcon {
            font-size: 32px;
            color: #f5f5f5;
            margin-top: 10px;
            padding-right: 2px;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;

            display: none;
          }
        }
        .list-selected {
          display: flex;
          align-items: center;
          padding: 4px;
          background-color: #035634;
          cursor: pointer;
          color: white;
          .sideOptionHover {
            background-color: #035634;
            color: white;
          }
          .sideOption {
            color: white;
          }

          &:hover {
            background-color: #1AC47D;
            color: white;
          }
          .icon {
            font-size: 25px;
            color: white;
            margin-top: 12px;
            max-height: 25px;
          }

          .menuIcon {
            font-size: 32px;
            color: #f5f5f5;
            margin-top: 10px;
            padding-right: 2px;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;

            display: none;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    border: 0.5px solid rgb(230, 227, 227);
    background-color: #1ac47d;
    display: flex;
    width: 41px;
    background-size: cover;
    box-shadow: 8px 4px 4px rgb(226, 226, 226);

    top: 1px;
    height: max-content;

    .top {
      z-index: 0;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
    }
    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227);
    }
    .logout {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      color: white;
      .sideOptionHover {
        background-color: #035634;
        color: white;
      }
      .sideOption {
        display: none;
      }
    }
    .center {
      align-items: center;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .title {
          font-size: 15px;
          font-weight: bold;
          display: none;
          color: white;
          cursor: pointer;
        }
        li {
          display: flex;
          align-items: center;
          padding: 5px;

          cursor: pointer;
          color: white;
          .sideOptionHover {
            background-color: #035634;
            color: white;
          }
          .sideOption {
            color: white;
          }
          &:hover {
            background-color: #1AC47D;
            color: white;
          }
          .icon {
            font-size: 18px;
            color: white;
            margin-top: 10px;
          }
          
          .menuIcon {
            font-size: 25px;
            color: #f5f5f5;
            margin-top: 10px;
            padding-right: 2px;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;

            display: none;
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
    }
  }

  .sideber-short {
    border: 0.5px solid rgb(230, 227, 227);
    display: flex;
    width: 70px;
    background-size: cover;
    position: sticky;
    top: 1px;
    height: 100vh;
    overflow-x: scroll;
    overflow-x: hidden;
  transition: 1.5s ease-in-out;
    
    .top {
      z-index: 0;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      .upperIcon {
        display: none;
      }
    }
    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227);
    }
    .logout {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      color: white;

      .sideOption {
        display: none;
      }
    }
    .center {
      align-items: center;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        .menuIcon {
          display: none;
        }
        .title {
          font-size: 15px;
          font-weight: bold;
          display: none;
          color: white;
          cursor: pointer;
        }
        li {
          display: flex;
          align-items: center;
          padding: 5px;

          cursor: pointer;
          color: white;
          .sideOptionHover {
            background-color: #035634;
            color: white;
          }
          .sideOption {
            color: white;
          }
          &:hover {
           
            color: white;
          }
          .icon {
            font-size: 18px;
            color: rgb(69, 57, 57);
            margin-top: 10px;
          }
          .menuIcon {
            font-size: 25px;
            color: #f5f5f5;
            margin-top: 10px;
            padding-right: 2px;
          }
          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;

            display: none;
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
    }
  }
}
