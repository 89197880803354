.logo_container{
    background: linear-gradient(180deg, #1AC47D 0%, rgba(26, 196, 125, 0.78) 100%);
}
.refund_policy{
    color: #808080;
font-family: Lexend;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120.5%; /* 17.68px */
letter-spacing: -0.16px;
width: 80%;
text-align: center;
}
.forgot_pass{
    color: #1A72C4;
font-family: Lexend;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 110.5%; /* 17.68px */
letter-spacing: -0.16px;
text-decoration-line: underline;
}
.Address_container{
    color: #FFF;
text-align: center;
font-family: Lexend;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: -0.14px;
}