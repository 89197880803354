// .CContainer {
//   display: grid;
//   grid-template-rows: min-content min-content auto auto;

//   background-color: white;
  
//   .notifications {
//     background-color: white;
//     display: grid;
//     grid-template-rows: 1fr 3fr 4fr;
//     margin: 12px;
//     box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
//     -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
//     -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
//     justify-self: center;
//     border-radius: 12px;
//     .top {
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       .top-para {
//         margin: 2vh;
//         padding: 1vh;
//         font-size: 3vh;
//         color: gray;
//       }
//     }
//     .mid {
//       background-color: #1ac47d;
//       padding: 20px;
//       .top-filter {
//         display: flex;
//         flex-direction: row;
//         gap: 20px;
//         margin: 20px;
//         flex-wrap: wrap;
//       }
//       .bottom-filter {
//         display: flex;
//         flex-direction: column;
//         gap: 30px;
//         margin: 20px;
//         flex-wrap: wrap;
//       }
//     }
//     .bottom {
//       margin: 12px;
//     }
//   }
// }
.push_container {
  display: grid;
  grid-template-rows: min-content min-content;

  background-color: white;
  .add-location {
    display: flex;
    /* align-items: flex-start; */
    justify-content: flex-start;
    margin: 10px 0 0 20px;
  }
  .table {
    height: auto;
  }
  .topRow {
    display: grid;

    grid-template-columns: max-content min-content;
    column-gap: 30px;
    padding: 10px;
    .filters {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      gap: 13px;
      align-items: flex-end;
      .dateSec {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .dwidgets {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      gap: 20px;
    }
  }
}
