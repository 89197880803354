.HomePage_table_container{
  overflow-x: auto;
}
.Table {
  padding: 10px;
  margin: 22px 10px 7px 10px;
  // min-height: 58vh;
  background-color: white;
  border-radius: 12px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.25);

  .title {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
  .label {
    display: flex;
    justify-content: center;
    border-radius: 39px;
    width: 80px;
    background: white;
    border: 3px solid green;
    .labelText {
      color: green;
      padding: 3px;
    }
  }

  .labelCompleted {
    display: flex;
    justify-content: center;
    border-radius: 30px;
    width: 80px;
    height: 24px;
    background: white;
    border: 3px solid rgb(183, 132, 39);
    .labelText {
      color: rgb(183, 132, 39);
      padding: 3px;
    }
  }
  .cellWithTable {
    display: felx;
    align-items: center;
    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
      color: rgba(42, 60, 136, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .cellWithStatus {
    padding: 5px;
    width: 10vh;
    height: 3vh;
    border-radius: 60%;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    align-items: center;

    &.Approved {
      background-color: black;
      color: green;
    }
    &.Pending {
      background-color: black;
      color: goldenrod;
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1pc rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1pc rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}
.custom-cell {
  font-family: "lexend", sans-serif; /* Replace with your desired font-family */
  color: #111;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.5%; /* 17.68px */
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7b7b7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #63fd789b;
}