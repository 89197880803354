.transaction-container {
  display: grid;
  grid-template-rows: min-content min-content auto auto;

  .transaction_heading{
    color: #111;
font-family: Lexend;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 110.5%; /* 24.31px */
  }

  .filter_button{
    @media screen and (max-width: 575px) {
      display: none;
     }
  }

  .modal_button_filter{
    display: none;
    @media screen and (max-width: 575px) {
     display: block;
    }
  }

  background-color: white;
  .topRow {
    display: flex;
    flex-direction: row;
    row-gap: 40px;
    padding: 10px;
    flex-wrap: wrap;
    
    .filters {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      gap: 13px;
      width: 20%;
      align-items: flex-end;
      flex-wrap: wrap;
      .dateSec {
        // display: flex;
        // flex-direction: column;
        // // gap: 10px
      }
     
    }

    .dwidgets {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      gap: 20px;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 575px) {
      .dwidgets {
        display: block;
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .topRow {
      display: flex;
      flex-direction: column;
    }
  }
}

.MuiAutocomplete-tag{
display: none !important;
// display: flex;
// position: relative;
// left: -570%;
}
.filter_header{
  background-color: #F6F8FA !important;
}
.react-datepicker__header {
  background-color: #fff;
  font-family: 'lexend';
}
.react-datepicker__day--today{
  background-color: #becac5 !important;
  border-radius: 50%;
    font-weight: 400;
}

.react-datepicker__day--selected{
  background-color: #1AC47D !important;
  border-radius: 50%;
    font-weight: 400;
}
.react-datepicker__day--in-range{
  background-color: #1AC47D !important;
  color: white;
  border-radius: 50%;
    font-weight: 400;
}