.booking-container {
  .filter {
    padding-top: 20px;
    margin-left: 20px;
  }

  .add-car {
    display: flex;
    /* align-items: flex-start; */
    justify-content: flex-start;
    margin: 10px 0 0 20px;
  }
  display: grid;
  grid-template-rows: min-content min-content auto auto;

  background-color: white;
  .topRow {
    display: grid;

    grid-template-columns: max-content min-content;
    column-gap: 30px;
    padding: 10px;
    .filters {
      display: flex;
      flex-direction: row;
      padding: 0;
      gap: 13px;
      align-items: flex-end;
      .dateSec {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .dwidgets {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      gap: 20px;
    }
  }
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

$primary-color: #19885A; // Change color here. C'mon, try it! 
$text-color: mix(#000, $primary-color, 64%);

form {
	display: flex;
	flex-wrap: wrap;
}

.Label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.375em;
	/* Accessible outline */
	/* Remove comment to use */ 
	/*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
	.Custom_Radio {
		position: absolute;
		left: -9999px;
		&:checked + .Custom_label {
			// background-color: mix(#fff, $primary-color, 84%);
      background-color: #E9FAF2;
			&:before {
				box-shadow: inset 0 0 0 0.2375em white;
        background: #111111;
		border: 1px solid #111111;
			}
		}
	}
	.Custom_label {
		display: flex;
		align-items: center;
		padding: 0.375em 1em 0.375em 0.375em;
		border-radius: 99em; // or something higher...
		transition: 0.25s ease;
    border: 1px solid #111111;
    font-family: "lexend";
    font-size: 13px;
	color: #111111;
    margin-left: 9px;
		&:hover {
			background-color: mix(#fff, $primary-color, 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.105em #111111;
		}
	}
}
.Cancelled_Label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.375em;
	/* Accessible outline */
	/* Remove comment to use */ 
	/*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
	.Cancelled_Custom_Radio {
		position: absolute;
		left: -9999px;
		&:checked + .Cancelled_Custom_label {
			// background-color: mix(#fff, $primary-color, 84%);
      background-color: white;
			&:before {
				box-shadow: inset 0 0 0 0.2375em white;
        background: #C41A1A;
		border: 1px solid #C41A1A;
			}
		}
	}
	.Cancelled_Custom_label {
		display: flex;
		align-items: center;
		padding: 0.375em 1em 0.375em 0.375em;
		border-radius: 99em; // or something higher...
		transition: 0.25s ease;
    border: 1px solid #C41A1A;
    font-family: "lexend";
    font-size: 13px;
	color: #C41A1A;
    margin-left: 9px;
		&:hover {
			background-color: mix(#fff, $primary-color, 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #C41A1A;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.105em #fefefe;
		}
	}
}
.Expired_Label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.375em;
	/* Accessible outline */
	/* Remove comment to use */ 
	/*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
	.Expired_Custom_Radio {
		position: absolute;
		left: -9999px;
		&:checked + .Expired_Custom_label {
			// background-color: mix(#fff, $primary-color, 84%);
      background-color: white;
			&:before {
				box-shadow: inset 0 0 0 0.2375em white;
        background: #C99456;
		border: 1px solid #C99456;
			}
		}
	}
	.Expired_Custom_label {
		display: flex;
		align-items: center;
		padding: 0.375em 1em 0.375em 0.375em;
		border-radius: 99em; // or something higher...
		transition: 0.25s ease;
    border: 1px solid #C99456;
    font-family: "lexend";
    font-size: 13px;
	color: #C99456;
    margin-left: 9px;
		&:hover {
			background-color: mix(#fff, $primary-color, 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.105em #C99456;
		}
	}
}
.Upcoming_Label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.375em;
	/* Accessible outline */
	/* Remove comment to use */ 
	/*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
	.Upcoming_Custom_Radio {
		position: absolute;
		left: -9999px;
		&:checked + .Upcoming_Custom_label {
			// background-color: mix(#fff, $primary-color, 84%);
      background-color: #fae9e9;
			&:before {
				box-shadow: inset 0 0 0 0.2375em white;
        background: green;
		border: 1px solid green;
			}
		}
	}
	.Upcoming_Custom_label {
		display: flex;
		align-items: center;
		padding: 0.375em 1em 0.375em 0.375em;
		border-radius: 99em; // or something higher...
		transition: 0.25s ease;
    border: 1px solid #19885A;
    font-family: "lexend";
    font-size: 13px;
	color: #19885A;
    margin-left: 9px;
		&:hover {
			background-color: mix(#fff, $primary-color, 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.105em #19885A;
		}
	}
}


// // Codepen spesific styling - only to center the elements in the pen preview and viewport
// .container {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	padding: 20px;
// }
// End Codepen spesific styling