.chargeSession_container {
  display: flex;

  padding: 10px;
  border-radius: 12px;
  height: 80px;
  background-color: #F6F8FA;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .title {
    font-weight: bold;
  }
  .content {
    font-size: 18px;
    font-weight: bold;
    color: rgb(61, 57, 57);
  }
  .item {
    font-size: 12px;
    font-weight: lighter;
    margin-top: 0;
    padding-top: 0;
    color: gray;
  }
}
@media screen and (max-width: 575px) {
  .csWidget {
    margin-bottom: 20px;
    width: -webkit-fill-available;
  }
}
