.databaseTable {
  padding: 20px;
  .filters {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }
  .cellWithTable {
    display: felx;
    align-items: center;
    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
      color: rgba(42, 60, 136, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;
    &.Approved {
      background-color: black;
      color: green;
    }
    &.Pending {
      background-color: black;
      color: goldenrod;
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1pc rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1pc rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}
