.content {
  display: flex;
  flex-direction: column;
  .customRow {
    display: flex;
    flex-direction: row;
    gap: 8vh;
  }
}
.headItem {
  display: flex;
  .head1 {
    align-self: flex-start;
  }
  .head2 {
    margin-left: auto;
    cursor: pointer;
  }
}
.innerTableHead {
  background-color: #EAF0F5;
  .innerTable {
    font-family: "lexend";
    font-weight: "600";
    font-size: "15px";
  }
}
small {
  color: red;
  font-size: large;
}
.custom-checkbox:checked {
  background-color: black; /* Set your desired color */
  border-color: black; /* Set your desired color */
}
// Time Picker Styling
.css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton{
  background-color: #1AC47D !important;
}
.css-eg3pzz-MuiClockPointer-thumb {
  background-color: #1ac47d !important;
  border: 16px solid #1ac47d !important;
 
}
.css-12t0dn4-MuiClockPointer-thumb {
  background-color: #1ac47d !important;
  border: 16px solid #1ac47d;
}
.css-d0vs79-MuiClockPointer-root {
  background-color: #1ac47d !important;
}
.css-umzx0k-MuiClock-pin {
  background-color: #1ac47d !important;
}

.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton{
  background-color: #1ac47d !important;
}
.selected_chip{
  background-color: red;
  color:white
}