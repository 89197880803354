@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
      font-family: "lexend", system-ui, sans-serif;
    }
  }

  .main_heading{
    color: #111;
font-family: Lexend;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 110.5%; /* 24.31px */
  }

  .rounded-checkbox {
    width:14px;
    height: 14px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid rgb(108, 108, 108);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .rounded-checkbox:checked {
    appearance: auto;
    clip-path: circle(50% at 50% 50%);
    background-color: rgb(71, 71, 71);
  }
  .css-1jzq0dw-MuiChip-label{
    font-family: 'lexend' !important;
  }

  // React month date picker dropdown font change
.react-datepicker__month-select option{
  font-family: 'lexend' !important;
  font-size: 15px;  
  }

  // React year date picker dropdown font change
  .react-datepicker__year-select option{
    font-family: 'lexend' !important;
    font-size: 15px;
  
  }

  // Date picker month dropdown css
  .react-datepicker__month-dropdown-container--select
  {
  font-family: 'lexend' !important;
  font-size: 15px !important;
  }
  // Date picker year dropdown css
  .react-datepicker__year-dropdown-container--select{
  font-family: 'lexend' !important;
  font-size: 15px !important;
  
  }
  // Date picker month dropdown css
  .react-datepicker__header__dropdown--select
  {
    padding: 6px 0px !important;
    display: flex;
    justify-content: space-between;
    background: #E8F9F2 !important;
    margin: 5px 0px !important;
  }
  
  // Date picker days name font change
  .react-datepicker__day-names .react-datepicker__day-name{
    font-family: 'lexend' !important;
    font-weight: 500;
  }
  // Date picker date font change
  .react-datepicker__month .react-datepicker__week{
    font-family: 'lexend' !important;
    font-weight: 500;
  }