.homeContainer {
  background-color: rgb(235, 240, 240);
  // .top-bar {
  //   display: flex;
  //   background-color: #1ac47d;
  //   justify-content: end;
  //   z-index: 1112121;
  //   height: 40px;
  //   position: sticky;
  // }
  .widgets {
    display: flex;
    padding: 10px 10px 0 10px;
    gap: 20px;
    margin-bottom: 12px;
  }
  .secContainer {
    display: grid;
    padding: 10px 10px 10px 10px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    .pieChart {
      grid-row-start: 2;
      grid-row-end: 4;
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    .widgets {
      display: block;
      padding: 10px;
    }
    .secContainer {
      display: grid;
      grid-template-rows: auto auto auto auto;
      grid-template-columns: 1fr;
      padding: 10px;
    }
  }
}

.card_heading {
  color: #111;
  font-family: "Lexend", sans-serif;
  // font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  @media (max-width: 790px) {
    font-size: 19px;
  }
  @media (max-width: 508px) {
    // font-size: 26px;
  }
}

.chargeProcessContainer {
  border-radius: 10px;
  background: #f7f9fb;
}

.chargeProcessCount {
  color: #111;
  font-family: "Lexend", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 110.5%; /* 28.73px */
  letter-spacing: -0.26px;
  :left {
    inset: 5;
  }
}

.faultContainer {
  border-radius: 10px;
  background: #f7f9fb;
}

.faultCount {
  color: #111;
  font-family: "Lexend", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 110.5%; /* 28.73px */
  letter-spacing: -0.26px;
}

.home_main_container {
  display: flex;
  width: 100%;

  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-out {
    opacity: 0;
    transform: translateY(-20px); /* Adjust for desired effect */
  }

  flex-direction: column-reverse; // Default flex-direction for all screen sizes
  // background: #6ad2a7;
  @media (min-width: 790px) {
    flex-direction: row; // Change flex-direction to row on screens larger than or equal to 790px (tablet and larger)
    // background: #ffa38e;
  }

  @media (max-width: 1024px) {
    // width: 100%;
    // background: yellow;
  }

  .left_container {
    width: 100%;
    @media (min-width: 790px) {
      // width: 53%; // Take 62% width on screens larger than or equal to 790px
      width: 50%; // Take 62% width on screens larger than or equal to 790px
    }
    @media (max-width: 1024px) {
      // width: 99%;
      // background: yellow;
    }

    .tab_heading {
      color: #111;
      font-family: "lexend";

      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 110.5%; /* 24.31px */
      letter-spacing: -0.22px;
      margin: 1px 0px 15px 17px;
    }

    .tab_Heading_container {
      margin: 17px 0px 8px 9px;
    }
    .detailed_heading {
      border-radius: 7px;
      background: #f7f9fb;
      border-left: 5px solid #6ad2a7;
      margin-right: 10px;
    }
    .detailed_heading h1 {
      color: #111;
      font-family: "lexend";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      padding: 12px 25px;
      @media (max-width: 790px) {
        font-size: 26px;
        color: red;
      }
    }
    .left {
      display: block;
      margin-bottom: 17px;
      @media (max-width: 790px) {
        display: none;
      }
    }
  }

  .statics_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (max-width: 1279px) {
      flex-direction: column;
      margin: 0px 2%;
    }
  }

  .right_container {
    width: 100%; // Take full width on small screens
    @media (min-width: 790px) {
      width: 50%; // Take 37% width on screens larger than or equal to 790px
    }
    .tab_heading {
      color: #111;
      font-family: "lexend";

      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 110.5%; /* 24.31px */
      letter-spacing: -0.22px;
      margin: 1px 0px 15px 17px;
      @media (max-width: 875px) {
        font-size: 28px;
        margin: 10px 0px;
      }
      @media (max-width: 375px) {
        font-size: 38px;
      }
    }

    .tab_Heading_container {
      margin: 17px 0px 17px 9px;
    }
    .detailed_heading {
      border-radius: 7px;
      background: #f7f9fb;
      border-left: 5px solid #6ad2a7;
      width: 98%;
    }
    .detailed_heading h1 {
      color: #111;
      font-family: "lexend";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      padding: 12px 25px;
      @media (max-width: 790px) {
        font-size: 22px;
        font-weight: 600;
      }
      @media (max-width: 568px) {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .right {
      display: none;
      @media (max-width: 790px) {
        display: block;
      }
    }
    // Changes
  }
  .left_stats {
    width: 100%; // Take full width on small screens
    @media (min-width: 790px) {
      width: 48%; // Take 48% width on screens larger than or equal to 790px
    }
    @media (max-width: 1279px) {
      width: 100%; // Take 98% width on screens larger than or equal to 790px
    }
  }
  .right_stats {
    width: 100%; // Take full width on small screens
    @media (min-width: 790px) {
      width: 48%; // Take 48% width on screens larger than or equal to 790px
    }
    @media (max-width: 1279px) {
      width: 100%; // Take 98% width on screens larger than or equal to 790px
    }
  }
  .cStatus_container {
    display: flex;
    flex-wrap: wrap; // Allow items to wrap on smaller screens
    justify-content: space-around;
    margin: 10px 0px;

    @media (max-width: 1090px) {
      flex-direction: column;
      margin: 10px 2%;
    }
  }

  .chargeContainer {
    width: 48%; // Take full width on small screens
    // @media (min-width: 790px) {
    //   width: 48%; // Take 48% width on screens larger than or equal to 790px
    // }
    @media (max-width: 1279px) {
      width: 98%;
    }
    background-color: #f7f9fb;
    border-radius: 10px;
    background: #f7f9fb;
    padding: 20px;
  }

  .chargeProcessContainer {
    border-left: 6px solid #6ad2a7;
    @media (max-width: 1279px) {
      width: 100%;
    }
  }

  .faultContainer {
    border-left: 6px solid #ffa38e;
    margin-left: 3px;
    @media (max-width: 1279px) {
      width: 100%;
      margin-top: 17px;
      margin-left: 2px;
    }
  }

  .widgets_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 1279px) {
      flex-direction: column;
      margin: 0px 8px;
    }
    @media (max-width: 790px) {
      width: 96%;
      margin: 0px auto;
    }
  }

  .card_container_stats {
    width: 100%; // Take full width on small screens
    height: 10.5rem;
    @media (min-width: 790px) {
      width: 48%; // Take 48% width on screens larger than or equal to 790px
    }
    @media (max-width: 1279px) {
      // width: 98%; Previos // Take 98% width on screens larger than or equal to 790px
      width: 100%;
      margin: 8px 0px;
    }
  }
  .tablet_circle {
    display: none; /* Show tablet_circle by default */
  }
  .tablet_res {
    display: block; /* Hide tablet_res by default */
  }

  @media (min-width: 842px) {
    /* On tablets and larger screens */
    .tablet_circle {
      display: block; /* Hide tablet_circle */
    }
    .tablet_res {
      display: none; /* Show tablet_res */
    }
  }

  @media (min-width: 1100px) {
    /* On desktop screens */
    .tablet_circle {
      display: none; /* Show tablet_circle */
    }
    .tablet_res {
      display: block; /* Hide tablet_res */
    }
    .card_container_stats {
      margin: 6px auto;
    }
  }
}
